import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import PostListing from "../components/PostListing"
import config from "../../data/SiteConfig"

const tagTitleStyle = css`
  font-size: 14px;
`

const TagTemplate = props => {
  const { tag } = props.pageContext
  const postEdges = props.data.allMarkdownRemark.edges
  return (
    <Layout>
      <div className="tag-container">
        <Helmet title={`Posts tagged as "${tag}" | ${config.siteTitle}`} />
        <h2 css={tagTitleStyle}>Posts with tag'{tag}'</h2>
        <PostListing postEdges={postEdges} />
      </div>
    </Layout>
  )
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            date
          }
        }
      }
    }
  }
`

export default TagTemplate
